@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    @variants responsive {
        .text-shadow-xs {
            text-shadow: 0.1px 0.1px 0.1px black;
        }
        .text-shadow {
            text-shadow: 2px 2px 4px black;
        }
        .text-shadow-md {
            text-shadow: 4px 4px 8px black;
        }
        .text-shadow-lg {
            text-shadow: 15px 15px 30px black;
        }
        .text-shadow-none {
            text-shadow: none;
        }
    }
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* background-color: #1A1B31; */
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Urbanist", sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
